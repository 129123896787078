<template>
    <data-grid
        :table-borderless="true"
        :border-table-header="true"
        :columns="columns"
        :data-source="entities.data"
        :loading="loadingSearch"
        @row-click="onRowClick"
    >
        <template #filter>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="flex flex-1 gap-x-3 gap-y-1">
                    <accountant-clients-documents-items-filter-search />
                </div>
                <div class="flex flex-1 items-center justify-end gap-3">
                    <template v-if="parentFolder">
                        <base-button
                            variant="primary"
                            size="sm"
                            icon="line-icons:files:folder"
                            @click="toggleAddNewFolder"
                        >
                            {{ $t('accountantClients.documents.addNewFolder') }}
                        </base-button>
                        <base-button
                            variant="primary"
                            size="sm"
                            icon="line-icons:general:upload-cloud-02"
                            @click="toggleUploadFileModal"
                        >
                            {{ $t('accountantClients.documents.uploadFile') }}
                        </base-button>
                    </template>
                </div>
            </div>
        </template>

        <template v-if="state.addingNewFolder" #additional-top-row>
            <accountant-clients-documents-items-add-folder
                v-if="parentFolder?.uuid"
                :tenant-id="tenantId"
                :parent-id="parentFolder?.uuid"
                @created-directory="createdDirectory"
            />
        </template>

        <template #column-name="{ item }">
            <div class="flex flex-1 items-center gap-x-3">
                <template v-if="isFolder(item)">
                    <div class="rounded-full bg-primary-100 p-2.5">
                        <base-icon
                            name="line-icons:files:folder"
                            variant="primary"
                        />
                    </div>
                    <template v-if="renameItem?.uuid === item.uuid">
                        <accountant-clients-documents-items-form-name
                            v-model="renameItem.name"
                            :disabled="loadingRename"
                            :error-message="errorMessageRename"
                            @click.stop
                            @on-submit="confirmRename"
                        />
                    </template>
                    <div v-else class="flex flex-col">
                        <span class="text-sm font-medium text-gray-900">
                            {{ getFolderName(item) }}
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div class="rounded-full bg-gray-100 p-2.5 text-gray-600">
                        <base-icon
                            :name="iconFileName(item)"
                            variant="inherit"
                        />
                    </div>
                    <template v-if="renameItem?.uuid === item.uuid">
                        <accountant-clients-documents-items-form-name
                            v-model="renameItem.name"
                            :disabled="loadingRename"
                            :error-message="errorMessageRename"
                            @click.stop
                            @on-submit="confirmRename"
                        />
                    </template>
                    <div v-else class="flex flex-col">
                        <div class="text-sm font-medium text-gray-900">
                            {{ item.full_name }}
                        </div>
                        <span class="text-sm font-normal text-gray-500">
                            {{ $filters.humanFileSize(item.size) }}
                        </span>
                    </div>
                </template>
            </div>
        </template>

        <template #column-contains="{ item }">
            <template v-if="isFolder(item)">
                {{
                    $t('accountantClients.documents.containsDocument', {
                        docs: totalChildDocs(item),
                        folders: totalChildFolders(item),
                    })
                }}
            </template>
        </template>

        <template #column-created_at="{ item }">
            <div
                v-if="item.created_at"
                class="text-sm font-normal text-gray-500"
            >
                <template v-if="!isFolder(item)">
                    {{ $filters.dateTimeHumanFormat(item.created_at) }}
                </template>
            </div>
        </template>

        <template #column-updated_at="{ item }">
            <div
                v-if="item.updated_at"
                class="text-sm font-normal text-gray-500"
            >
                <template v-if="!isFolder(item)">
                    {{ $filters.dateTimeHumanFormat(item.updated_at) }}
                </template>
            </div>
        </template>

        <template #column-author="{ item }">
            <div v-if="item.author" class="text-sm font-normal text-gray-500">
                {{ item.author.full_name }}
            </div>
        </template>

        <template #column-action="{ item }">
            <base-context-menu
                v-if="!isFolder(item) || item.editable || item.deletable"
            >
                <base-context-menu-item
                    v-if="!isFolder(item)"
                    icon="line-icons:general:download-01"
                    @click="confirmDownload(item)"
                >
                    {{ $t('documents.list.contextMenu.download') }}
                </base-context-menu-item>

                <base-context-menu-item
                    v-if="item.editable"
                    icon="line-icons:editor:text-input"
                    @click="setRename(item)"
                >
                    {{ $t('documents.list.contextMenu.rename') }}
                </base-context-menu-item>

                <base-context-menu-item
                    v-if="item.deletable"
                    icon="line-icons:general:trash-01"
                    class="text-danger-600"
                    @click="setEntity(item)"
                >
                    {{ $t('general.remove') }}
                </base-context-menu-item>
            </base-context-menu>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>

    <base-delete-modal
        :loading="loadingDelete"
        :show="entity?.uuid"
        :name="entity?.name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />

    <accountant-clients-documents-items-upload-files
        v-if="parentFolder?.uuid"
        :tenant-id="tenantId"
        :parent-id="parentFolder?.uuid"
        :show="state.uploadFileModal"
        @modal-close="toggleUploadFileModal"
        @on-finished-upload="onFinishUpload"
    />
</template>

<script setup>
import { iconFileName, isFolder } from '@tenant/utils/helper'
import { getFolderName } from '@tenant/modules/tenant/documents/utils/helper.utils'
import { useClientDocument } from '@tenant/modules/accountant/practise-clients/composables/use-client-document'

const emit = defineEmits(['refresh-grid'])
const props = defineProps({
    tenantId: {
        type: String,
        required: true,
    },
})

const emitter = useEmitter()
const route = useRoute()
const { t } = useI18n()

const columns = [
    {
        property: 'name',
        label: t('accountantClients.documents.fileName'),
    },
    {
        property: 'contains',
        label: t('accountantClients.documents.contains'),
    },
    {
        property: 'created_at',
        label: t('accountantClients.documents.dateUploaded'),
    },
    {
        property: 'updated_at',
        label: t('accountantClients.documents.lastUpdated'),
    },
    {
        property: 'author',
        label: t('accountantClients.documents.uploadedBy'),
    },
    {
        property: 'actions',
        label: '',
    },
]

const state = reactive({
    addingNewFolder: false,
    uploadFileModal: false,
})

const {
    renameItem,
    entities,
    entity,
    parentFolder,
    errorMessageRename,
    loadingSearch,
    loadingDelete,
    loadingRename,
    totalChildFolders,
    totalChildDocs,
    refreshSearch,
    setEntity,
    setRename,
    openFolder,
    onDelete,
    onRename,
    onDownload,
} = useClientDocument(props.tenantId)

watch(
    () => route.query,
    (queries) => {
        if (!queries) {
            return
        }

        state.addingNewFolder = false
        refreshSearch()
    },
    {
        immediate: true,
    }
)

const refreshGrid = () => {
    refreshSearch()
    emit('refresh-grid')
}

const toggleAddNewFolder = () => {
    state.addingNewFolder = !state.addingNewFolder
}

const createdDirectory = () => {
    state.addingNewFolder = false
    refreshGrid()
}

const toggleUploadFileModal = () => {
    state.uploadFileModal = !state.uploadFileModal
}

const onFinishUpload = () => {
    toggleUploadFileModal()
    refreshGrid()
}

const confirmDelete = () => {
    onDelete().then(() => {
        refreshGrid()
    })
}

const confirmDownload = async (item) => {
    try {
        emitter.emit('set-loading', true)
        await onDownload(item)
    } finally {
        emitter.emit('set-loading', false)
    }
}

const confirmRename = () => {
    onRename().then(() => {
        refreshGrid()
    })
}

const onRowClick = (_, __, item) => {
    openFolder(item)
}
</script>
